import { BackgroundBox, LineLoader, PageTitle } from 'Components/MyAccount/Common';
import ProductCard from 'Components/Productcard';
import { useEffect, useMemo, useState, useContext, useRef, memo } from "react";
import DomainContext from "Context/DomainContext";
import { useDispatch, useSelector } from "react-redux";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useLocation, useNavigate } from "react-router-dom";
import { SessionExpiredLogout } from "Utilities";
import { APIQueryPost } from "APIMethods/API";
import Seo from "Components/Seo/Seo";
import "./styles.scss"
import DesignCard from './DesignCard';

const Mydesign = () => {
    const { baseURL, storeId, expofitUrl } = useContext(DomainContext);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const APIRef = useRef(false);
    const [numCount, setNumCount] = useState(6);
    const [productsData, setProductsData] = useState([]);
    const [productsDataAll, setProductsDataAll] = useState([]);
    const customerDetails = useSelector(state => state?.customerDetails);
    const isSessionExpired = useSelector((state) => state?.isSessionExpired);
    const token = useSelector(state => state?.token);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [designData, setDesignData] = useState(null);
    const [totalRecords, setTotalRecords] = useState(null);
    const customerId = useSelector((state) => state?.customerDetails?.id);
    const resultdata = designData?.result?.[0]?.designdata
    const [mydesign , setmydesign] = useState(false)
   // const targetRef = useRef(null);
      const Mydesign = {
        isLoader: true,
        loaderAction: (bool) => setLoading(bool),
        setGetResponseData: (resData) => {
          setDesignData(resData?.data);
          setProductsData(resData?.data?.[0]?.products);
          setTotalRecords(resData?.data?.result?.[0]?.totalRecords);
        },

        axiosData: {
          url: `${baseURL}/productdesigner/getdesigns`, 
          headers: { Authorization: `Bearer ${token}` },  
          paramsData: {  
            customerId: customerDetails?.id ? customerDetails?.id : 1, 
            page: currentPage || 1, 
            limit: numCount
          }
        },
        getStatus: (res) => {
          SessionExpiredLogout(dispatch, res?.status, navigate, isSessionExpired);
        }
      };

      useEffect(() => {
        if (!APIRef.current) {
          APIQueryPost(Mydesign);
          setmydesign(true)
          APIRef.current = true;
          setTimeout(() => APIRef.current = false, 200);
        }
      }, [location, currentPage,]);


      // const handleLoadMore = () => {
      //   setNumCount((prevCount) => {
      //     const newCount = prevCount + 1;
      //     APIQueryPost(Mydesign); // Pass the newCount directly
      //     return newCount;
      //   });
      // };

      const handleLoadMore = () => {
        setNumCount((prevCount) => prevCount + 6);
      };

      useEffect(() => {
        if (numCount > 0) {
          APIQueryPost(Mydesign); 
        }
      }, [numCount]);

// useEffect(() => {
//   const options = {
//     root: null, 
//     rootMargin: "100px 0px", 
//     threshold: 0.5, 
//   };

//   const callback = (entries) => {
//     entries.forEach((entry) => {
//       if (entry.isIntersecting) {
//         if (entry.target === targetRef.current) {
//           setNumCount((prevOffset) => prevOffset + 1); 
//         }
//       }
//     });
//   };

//   const observer = new IntersectionObserver(callback, options);
//   if (targetRef.current) {
//     observer.observe(targetRef.current);
//   }

//   return () => {
//     if (targetRef.current) {
//       observer.unobserve(targetRef.current); 
//     }
//   };
// }, [targetRef]); 




    return (
        <>
            <Seo
                metaTitle={storeId === 1 ? "Mijn ontwerpen | Promofit.nl" : "Mijn ontwerpen Expofit.nl"}
                metaDescription="Mijn ontwerpen"
                metaKeywords="Mijn ontwerpen"
            />
            <div className='designlist__page'>
                <div className='flex gap-6 col'>
                    <div className="flex gap-y-6 gap-x-7 wrap">
                        <BackgroundBox className='wishmain pt-6 pb-7 px-5 lg-pt-5 lg-px-8 lg-pb-8 flex-1 flex col space-between'>
                              {/* <div ref={targetRef}></div> */}
                            <div className="flex col pb-3">
                                <PageTitle>Mijn ontwerpen ({(designData?.result?.[0]?.totalRecords) ? designData?.result?.[0]?.totalRecords : 0})</PageTitle>
                                {
                                  resultdata?.length > 0 ?
                                <span className='Mydesign_content'>Ontwerpen die u heeft opgeslagen in de Online design tool vindt u hier terug. U kunt de ontwerpen openen en bewerken. Bent u tevreden? Dan kunt u de producten bestellen/aanvragen met de gemaakte designs. Wij controleren altijd handmatig de gemaakte ontwerpen.</span>
                                : ""
                                }
                                {loading ? (
                                    <div className="designlist__grid flex row wrap pt-1">
                                        {["", "", "", "", "", "", "", ""].map((item, ind) => (
                                            <div className="designitem" key={`wishlistItemsLoad${ind + 1}`}>
                                                <DesignCard
                                                    loading={loading}
                                                    key={`wishlist_product_loading_reults_${ind}`}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                ) : resultdata?.length > 0 ? (
                                    <div className="designlist__grid flex row wrap pt-1" >
                                        {resultdata?.map((item, index) => (
                                            <div className="designitem" key={`wishlistItems${index + 1}`}>
                                                <DesignCard
                                                    data={item}
                                                    key={`wishlist__data${index}`}
                                                    isWishList={true}
                                                    mydesign={mydesign}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <></>
                                )}
                                          {
                                            designData?.status == "Error" && designData?.message ? (
                                              <p className="tc fs-15">{designData?.message}</p>
                                            ) : null
                                          }

                                          {
                                            resultdata?.length === 0 ? (
                                          <p className="tc fs-15">Product design is not available</p>
                                            ) : ""
                                          }
                                  
                                  {
                                    designData?.status == "Error" || resultdata?.length === 0  ? (
                                     ""
                                    ) :
                                       <div class="flex center pt-15 lg-pt-21">
                                      <button aria-label="button" 
                                      class="outlined sm py-3 px-7 r-9 fw-700 outlined  primary" 
                                      onClick={handleLoadMore}
                                      disabled={numCount >= totalRecords} 
                                      >Laad meer artikelen
                                      </button>
                                      </div>
                                  }
                            </div>

                        </BackgroundBox>
                    </div>
                </div>
            </div>
        </>
    )
};

export default memo(Mydesign);