import React, { useEffect, useContext } from 'react';
import "./styles.scss"
import Seo from 'Components/Seo/Seo';
import DomainContext from 'Context/DomainContext';
function UpdatePage() {
    const { storeId } = useContext(DomainContext);



    return (
        <React.Fragment>
            {storeId == 1 ?
                <>
                    <Seo
                        robots="NOINDEX,NOFOLLOW"
                    />
                    <div className='container px-4 update_page'>
                        <div className='py-4 pt-8 pt-xl-12'>
                            <h1 className='fs-32 fw-700 pt-4 pb-8'>
                                Datalek bij Promofit: Beveiliging van klantgegevens
                            </h1>
                            <p class="fs-15 pb-4 line-6 "> Beste klanten,</p>
                            <p class="fs-15 pb-4 line-6 ">
                                Bij Promofit nemen wij de beveiliging van uw gegevens uiterst serieus. We willen u informeren over een recent datalek dat heeft plaatsgevonden in ons systeem.
                            </p>
                        </div>
                        <div className='py-4'>
                            <h1 class="fs-20 fw-700 pb-2 ">
                                1.⁠ ⁠Wat is er gebeurd?

                            </h1>
                            <p class="fs-15 pb-4 line-6">
                                Onlangs werd er een datalek gemeld door cybercriminaliteit, waarbij klantgegevens mogelijk zijn blootgesteld. Er is onrechtmatige toegang geweest tot bepaalde klantgegevens, maar er is geen toegang verkregen tot gevoelige informatie zoals wachtwoorden of financiële gegevens.

                            </p>
                        </div>
                        <div className='py-4'>
                            <h1 class="fs-20 fw-700 pb-2 ">
                                2.⁠ ⁠Welke gegevens zijn gelekt?
                            </h1>

                            <ul className='py-2'>
                                <li>⁠Naam</li>
                                <li>E-mailadres</li>
                                <li> ⁠Factuuradres (alleen bij bestellingen)
                                </li>
                                <li>
                                    ⁠Leveradres (alleen bij bestellingen)
                                </li>
                            </ul>
                            <p class="fs-15 pb-4 line-6">


                                Er is geen toegang verkregen tot wachtwoorden, betalingsgegevens of andere gevoelige informatie.

                            </p>
                        </div>

                        <div className='py-4'>
                            <h1 class="fs-20 fw-700 pb-2 ">
                                3.⁠ ⁠Wat hebben we ondernomen?
                            </h1>
                            <p class="fs-15 pb-4 line-6">
                                Na het incident hebben we onmiddellijk het datalek gemeld bij de Autoriteit Persoonsgegevens (AP) en een e-mail verstuurd naar de getroffen klanten. Daarnaast hebben we onze interne processen aangescherpt, kwetsbaarheden verholpen en extra beveiligingsmaatregelen genomen om de kans op herhaling te verkleinen.
                            </p>
                        </div>

                        <div className='py-4'>
                            <h1 class="fs-20 fw-700 pb-2 ">
                                4.⁠ ⁠Aanbevelingen voor getroffen klanten
                            </h1>
                            <p class="fs-15 pb-4 line-6">

                                Als u denkt dat uw gegevens mogelijk zijn getroffen, raden wij u aan extra waakzaam te zijn voor phishing-e-mails of andere vormen van oplichting. Klik niet op onbekende links en deel geen gevoelige informatie als u twijfelt over de afzender.

                            </p>
                            <p class="fs-15 pb-4 line-6">

                                Bij Promofit blijven we ons inzetten voor de bescherming van uw gegevens. Mocht u vragen hebben of meer informatie wensen, neem dan gerust contact met ons op.
                            </p>

                        </div>

                        <div className='py-4'>

                            <p class="fs-15 pb-2 ">
                                Met vriendelijke groet,
                            </p>
                            <p class="fs-15 pb-4 ">
                                Het Promofit-team
                            </p>
                        </div>
                    </div>
                </>
                :
                <>
                    <Seo
                        robots="NOINDEX,NOFOLLOW"
                    />
                    <div className='container px-4 update_page'>

                        <div className='py-4 pt-8 pt-xl-12'>
                            <h1 className='fs-32 fw-700 pt-4 pb-8'>
                                Datalek bij Expofit: Beveiliging van klantgegevens

                            </h1>
                            <p class="fs-15 pb-4 line-6 ">Beste klanten,</p>
                            <p class="fs-15 pb-4 line-6 ">
                                Bij Expofit nemen wij de beveiliging van uw gegevens uiterst serieus. We willen u informeren over een recent datalek dat heeft plaatsgevonden in ons systeem.

                            </p>
                        </div>
                        <div className='py-4'>
                            <h1 class="fs-20 fw-700 pb-2 ">
                                1.⁠ ⁠Wat is er gebeurd?

                            </h1>
                            <p class="fs-15 pb-4 line-6">

                                Onlangs werd er een datalek gemeld door cybercriminaliteit, waarbij klantgegevens mogelijk zijn blootgesteld. Er is onrechtmatige toegang geweest tot bepaalde klantgegevens, maar er is geen toegang verkregen tot gevoelige informatie zoals wachtwoorden of financiële gegevens.
                            </p>
                        </div>
                        <div className='py-4'>
                            <h1 class="fs-20 fw-700 pb-2 ">
                                2.⁠ ⁠Welke gegevens zijn gelekt?
                            </h1>

                            <ul className='py-2'>
                                <li>Naam</li>
                                <li>E-mailadres</li>
                                <li>
                                    Factuuradres
                                </li>
                                <li>
                                    Leveradres
                                </li>
                            </ul>
                            <p class="fs-15 pb-4 line-6">


                                Er is geen toegang verkregen tot wachtwoorden, betalingsgegevens of andere gevoelige informatie.

                            </p>
                        </div>

                        <div className='py-4'>
                            <h1 class="fs-20 fw-700 pb-2 ">
                                3.⁠ ⁠Wat hebben we ondernomen?
                            </h1>
                            <p class="fs-15 pb-4 line-6">
                                Na het incident hebben we onmiddellijk het datalek gemeld bij de Autoriteit Persoonsgegevens (AP) en een e-mail verstuurd naar de getroffen klanten. Daarnaast hebben we onze interne processen aangescherpt, kwetsbaarheden verholpen en extra beveiligingsmaatregelen genomen om de kans op herhaling te verkleinen.
                            </p>
                        </div>

                        <div className='py-4'>
                            <h1 class="fs-20 fw-700 pb-2 ">
                                4.⁠ ⁠Aanbevelingen voor getroffen klanten
                            </h1>
                            <p class="fs-15 pb-4 line-6">
                                Als u denkt dat uw gegevens mogelijk zijn getroffen, raden wij u aan extra waakzaam te zijn voor phishing-e-mails of andere vormen van oplichting. Klik niet op onbekende links en deel geen gevoelige informatie als u twijfelt over de afzender.

                            </p>
                            <p class="fs-15 pb-4 line-6">

                                Bij Expofit blijven we ons inzetten voor de bescherming van uw gegevens. Mocht u vragen hebben of meer informatie wensen, neem dan gerust contact met ons op.
                            </p>

                        </div>

                        <div className='py-4'>

                            <p class="fs-15 pb-2 ">
                                Met vriendelijke groet,
                            </p>
                            <p class="fs-15 pb-4 ">
                                Het Expofit-team

                            </p>
                        </div>
                    </div>
                </>
            }
        </React.Fragment>


    )
}

export default UpdatePage
