import React, { useEffect } from 'react';
import Cart from 'Components/CartQuote/Cart';
import useScrollToTop from "Components/Hooks/useScrollToTop";
import { useEffectOnce } from 'Components/Hooks/useEffectOnce';

function CartPage() {
  useScrollToTop();
 
  useEffectOnce(() => {
    const resolution = `${window.screen.width} x ${window.screen.height}`;
    const postUrl = `analytical_info/action/store`;
    const request = new XMLHttpRequest();
    request.open('POST', postUrl, true);
    request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    request.send(`resolution=${resolution}`);
  });


  return (
    <div className=''>
      <Cart />
    </div>
  );
}

export default CartPage;
